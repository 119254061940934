<template>
  <div class="appt-store pt-0">
    <div class="appt-cap1 t-bold mb-3">{{ title }}</div>
    <div class="tabs" role="tablist">
      <b-card
        no-body
        class="mb-1 accordion"
        v-for="(category, index) in services"
        :key="category.id"
      >
        <b-button
          class="accordion-btn"
          block
          v-b-toggle="'accordion-' + category.id"
          ><img class="cap-img mr-3" :src="category.category_image" /><span
            class="category-name"
            >{{ category.name }}</span
          ></b-button
        >
        <b-collapse
          :id="'accordion-' + category.id"
          accordion="my-accordion"
          role="tabpanel"
          class="service-options"
          :visible="activeDepartment == index"
        >
          <b-card-body>
            <b-form-group label="" class="checkbox-area">
              <b-form-checkbox-group
                class="level2-checkbox"
                v-model="user_services"
                name="service_selections"
              >
                <b-form-checkbox
                  v-for="service in category.core_services"
                  :value="{
                    id: service.id,
                    name: service.name,
                    category: category.name,
                  }"
                  :key="service.id"
                >
                  {{ service.alias_name }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-card-body>
          <div class="off-site-area" v-if="index > 0">
            <b-form-checkbox v-model="off_site" name="check-button" switch>
              <span><span class="desktop">An </span>Off-Site Visit?</span>
            </b-form-checkbox>
            <i class="fa fa-exclamation-circle" :id="'tooltip-' + index"></i>
            <b-tooltip ref="tooltip" :target="'tooltip-' + index">
              We can come to your location to perform work and provide
              estimates. <br />
              Select this option if you require an off-site visit.
            </b-tooltip>
          </div>
        </b-collapse>
      </b-card>
    </div>
    <div class="goto-next">
      <div class="btn-back c-pointer" @click="back">Back</div>
      <div
        class="btn-next add-next"
        :class="{ 'active c-pointer': complete }"
        @click="next"
      >
        Next
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
  data: () => {
    return {
      level1_selector: "",
      level2_selector: {
        automotive: [],
        residential: [],
        commercial: [],
        marine: [],
      },
      service_options: ["automotive", "residential", "commercial", "marine"],
      level1_chks: {
        automotive: false,
        residential: false,
        commercial: false,
        marine: false,
      },
      off_site_checked: {
        automotive: false,
        residential: false,
        commercial: false,
        marine: false,
      },
    };
  },
  mounted() {},
  computed: {
    services() {
      return this.$store.state.form_service_options;
    },
    complete() {
      return this.$store.getters.formServices;
    },
    activeDepartment() {
      if (this.$store.getters.hasAutomotive) {
        return 0;
      }
      if (this.$store.getters.hasResidential) {
        return 1;
      }
      if (this.$store.getters.hasCommercial) {
        return 2;
      }
      if (this.$store.getters.hasMarine) {
        return 3;
      }
      return 0;
    },
    service_category: {
      get() {
        return this.$store.state.user.service_category;
      },
      set(value) {
        this.$store.commit("setUserServiceCategory", value);
      },
    },
    user_services: {
      get() {
        return this.$store.state.user.services;
      },
      set(value) {
        this.$store.commit("setUserServices", value);
      },
    },
    off_site: {
      get() {
        return this.$store.state.user.off_site;
      },
      set(value) {
        this.$store.commit("setOffSite", value);
      },
    },
  },
  methods: {
    next() {
      if (this.complete) {
        if (this.off_site) {
          if (
            !this.$store.getters.hasCommercial &&
            !this.$store.getters.hasResidential &&
            !this.$store.getters.hasMarine
          ) {
            this.$store.commit("cancelOffsite");
          }
        }
        this.$gtm.trackEvent({
          event: "form_services_selected",
          category: "Lead",
          action: "Services Selected",
          noninteraction: true,
        });
        this.$emit("next");
      }
    },
    back() {
      this.$emit("back");
    },
    onTooltipOpen() {
      this.$refs.tooltip.$emit("open");
    },
    onTooltipClose() {
      this.$refs.tooltip.$emit("close");
    },
  },
};
</script>
<style>
.appt-store .accordion-btn {
  color: black;
  font-weight: bold;
  text-align: left;
  padding: 20px 10px;
  position: relative;
  background: transparent;
}
.appt-store .accordion-btn:hover {
  background: #f1f1f1;
  color: black;
}
.appt-store .accordion-btn:focus,
.appt-store .accordion-btn:active,
appt-store.accordion-btn.collapsed {
  background: white;
  background-color: white !important;
  color: black !important;
}
.appt-store .accordion-btn.not-collapsed {
  background: #333;
  background-color: #333 !important;
  color: white !important;
}

.accordion-btn:after {
  font-size: 15px;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #222;
  transition: all 0.35s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  content: "\f054";
  font-family: "FontAwesome";
  font-weight: 100;
  margin-right: 5px;
}
.accordion-btn.not-collapsed:after {
  content: "\f078";
  color: white;
}
.appt-store .checkbox-area {
  padding: 0px;
}
.service-options {
  background: #f3f3f3;
}
.accordion-btn .category-name {
  font-size: 24px;
  font-weight: bold;
}
.btn.accordion-btn {
  display: flex;
  align-items: center;
}
.accordion-btn .cap-img {
  min-width: 90px;
  max-width: 90px;
  height: auto;
}
.appt-store .card.accordion {
  border: 0px solid transparent;
}
.accordion-btn.collapsed {
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
}
</style>
