<template>
  <div class="appt-date">
    <div class="appt-cap1 t-bold">{{ title }}</div>
    <b-row v-if="$store.state.components.appointment.validation_failed">
      <b-col>
        <p class="error-message text-center font-weight-bold mt-2 mb-2">
          The Date You Requested is Invalid. Please Select A New Date & Try
          Again.
        </p>
      </b-col>
    </b-row>
    <b-overlay :show="!swiperReady" rounded="sm">
      <b-row>
        <b-col class="sel-day-container">
          <swiper
            :options="swiperOptions"
            navigation
            :modules="modules"
            ref="swiper"
            @activeIndexChange="fakeIndexChange"
            @ready="ready"
            @slideChange="slideChange"
            @reachEnd="carouselEmpty"
            @reachBeginning="carouselAtStart"
            @click="carouselClick"
            @realIndexChange="indexChange"
            @toEdge="detectEdge"
            virtual
            v-if="initial_dates_loaded"
          >
            <swiper-slide
              class="btn-day"
              v-for="(d, index) in available_dates"
              :key="index"
              :virtualIndex="index"
            >
              <button
                type="button"
                class="btn-day-area"
                @click="selectDay(d, index)"
                :disabled="d.available == 0"
              >
                <div
                  class="btn-day"
                  :class="{
                    active: index == active && user_selected_date !== null,
                    disabled: d.available == 0,
                  }"
                >
                  <span class="lbl-date">{{ d.dayOfWeek }}</span>
                  <span class="lbl-day">{{ d.dateOfWeek }}</span>
                </div>
              </button>
            </swiper-slide>
            <template v-slot:button-prev>
              <div
                class="swiper-button-prev"
                @click="$refs.swiper.swiperInstance.slidePrev()"
                :class="{ disabled: !navBack }"
              ></div>
            </template>
            <template v-slot:button-next>
              <div
                class="swiper-button-next"
                @click="$refs.swiper.swiperInstance.slideNext()"
                :class="{ 'ajax-spinner': datesFetching }"
              ></div>
            </template>
          </swiper>

          <b-spinner
            type="grow"
            label="spinning"
            class="ajax-spinner"
            v-if="datesFetching"
          ></b-spinner>
        </b-col>
      </b-row>
    </b-overlay>
    <div class="sel-time-container">
      <div class="selected-date w-100">{{ showDateTime }}</div>
      <div
        class="date-time-message w-100 text-center text-italic"
        v-if="user_selected_date && user_selected_time == null"
      >
        Select a Time From Below*
      </div>
      <div class="time-area" v-if="user_selected_date !== null">
        <div
          class="time-row"
          v-for="(k, index) in times"
          :key="k.value"
          :class="{
            active: index == activeTime && activeTime !== false,
            disabled: k.disabled,
          }"
          @click="selectTime(index)"
        >
          {{ k.label }}
        </div>
      </div>
    </div>
    <div class="goto-next">
      <div class="btn-back c-pointer" @click="back">Back</div>
      <div
        class="btn-next add-next"
        :class="{ 'active c-pointer': complete }"
        @click="next"
      >
        Next
      </div>
    </div>
  </div>
</template>
<script>
import { Navigation, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    title: String,
  },
  data: () => {
    return {
      modules: [Navigation, Virtual],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView: 7,
        edgeSwipeDetection: true,
        breakpoints: {
          320: {
            slidesPerView: 2,
          },
          411: {
            slidesPerView: 3,
          },
          600: {
            slidesPerView: 4,
          },
          700: {
            slidesPerView: 5,
          },
          800: {
            slidesPerView: 6,
          },
          1024: {
            slidesPerView: 5,
          },
          1100: {
            slidesPerView: 6,
          },
          1300: {
            slidesPerView: 7,
          },
        },
      },
      navBack: false,
      datesFetching: false,
      swiperReady: false,
      activeDay: null,
      selected_date: null,
      selected_time: null,
      days: [], // lbl_date, lbl_day, active, date
      times: [
        { value: "8:00", label: "8:00 am", disabled: false, active: false },
        { value: "8:30", label: "8:30 am", disabled: false, active: false },
        { value: "9:00", label: "9:00 am", disabled: false, active: false },
        { value: "9:30", label: "9:30 am", disabled: false, active: false },
        { value: "10:00", label: "10:00 am", disabled: false, active: false },
        { value: "10:30", label: "10:30 am", disabled: false, active: false },
        { value: "11:00", label: "11:00 am", disabled: false, active: false },
        { value: "11:30", label: "11:30 am", disabled: false, active: false },
        { value: "12:00", label: "12:00 pm", disabled: false, active: false },
        { value: "12:30", label: "12:30 pm", disabled: false, active: false },
        { value: "1:00", label: "1:00 pm", disabled: false, active: false },
        { value: "1:30", label: "1:30 pm", disabled: false, active: false },
        { value: "2:00", label: "2:00 pm", disabled: false, active: false },
        { value: "2:30", label: "2:30 pm", disabled: false, active: false },
        { value: "3:00", label: "3:00 pm", disabled: false, active: false },
        { value: "3:30", label: "3:30 pm", disabled: false, active: false },
        { value: "4:00", label: "4:00 pm", disabled: false, active: false },
        { value: "4:30", label: "4:30 pm", disabled: false, active: false },
        { value: "5:00", label: "5:00 pm", disabled: false, active: false },
        { value: "5:30", label: "5:30 pm", disabled: false, active: false },
      ],
      startDateAvailable: new Date(
        new Date().getTime() + 3 * 24 * 60 * 60 * 1000
      ),
      today: new Date(),
      availability: null,
    };
  },
  created() {
    //@TODO - initialSlide is a property that may be available for setting an active slide, though the lookup process may nullify the need
  },
  mounted() {
    if (!this.initial_dates_loaded) {
      //@TODO-  Refactor this to just a dispatch call vs. the bootstrapped method below which likely doesn't use a dynamic storeID anyway
      this.getAvailableDates();
    }
    //@TODO - We should probably never save appt selections between sessions, once the user has closed the browser we will not persist the appt-date, which means we'll need to route the user to thats step always
    //@TODO - Can likely do the date check after the initial if logic, and if the date is wrong then we can just generate the day_nav again
    if (this.$store.state.user.requested_appointment !== null) {
      //@TODO - add logic for when a date was selected but a time may not have been
      this.selected_date = this.$store.state.user.requested_appointment.date;
      this.selected_time = this.$store.state.user.requested_appointment.time;
    }
    //this.swiperInstance.mounted
    /*
    @TODO - If we run into more issues with Swiper not being ready, we can use the below wrapper to help with ASYNC issues
    this.$nextTick(function()=> {});
     */
    if (this.initial_dates_loaded && this.active) {
      //this.$refs.swiper.use(Navigation);
      // User Has Active Date Saved From Previous Time - Change Slide to Active Slide (If Still Available)
      this.swiperInstance.slideTo(this.active);
      if (this.user_can_previous) {
        this.navBack = true;
      } else {
        this.navBack = false;
      }
    }
  },
  computed: {
    user_store() {
      return this.$store.getters.formStore;
    },
    user_can_previous() {
      //const vm = this;
      if (this.swiperInstance && this.swiperInstance.mounted) {
        if (this.swiperInstance.isBeginning === true) {
          return false;
        }
        if (this.swiperInstance.isBeginning === false) {
          return true;
        }
      }
      return false;
    },
    initial_dates_loaded() {
      if (this.available_dates == null) {
        return false;
      } else {
        if (this.available_dates.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    user_selected_date: {
      get() {
        return this.$store.state.user.requested_appointment_date;
      },
      set(dateObj) {
        //this.$store.commit('appointmentValidationFailed',false);
        this.$store.commit("userSelectedAppointmentDate", dateObj);
      },
    },
    user_selected_time: {
      get() {
        return this.$store.state.user.requested_appointment_time;
      },
      set(timeObj) {
        this.$store.commit("userSelectedAppointmentTime", timeObj);
      },
    },
    available_dates() {
      return this.$store.state.components.appointment.availability;
      // if availability.length > 0 (form is done processing)
    },
    user_selection() {
      return this.$store.state.user.requested_appointment;
    },
    complete() {
      return this.$store.getters.formAppointment;
    },
    swiperInstance() {
      return this.$refs.swiper.swiperInstance;
    },
    showDateTime() {
      //@TODO - Refactor to the use the computed property?
      if (this.user_selected_date == null) {
        return "Select a Valid Date From Options Above";
      }
      var show_label = "Choose An Available Time Slot";
      if (this.user_selected_date !== null) {
        show_label =
          this.user_selected_date.dayOfWeekProper +
          ", " +
          this.user_selected_date.month +
          " " +
          this.user_selected_date.dateOfWeekProper;
      }
      if (this.user_selected_time !== null) {
        show_label += " @ " + this.user_selected_time.label;
      }
      if (this.selected_date && this.selected_time) {
        //@TODO - Can possibly use validation logic here again to prevent users from 'locking' in days during a previous session
        //@TODO - Refactor the eventBus logic
        // EventBus emits to Appt_Modal to activate the next button according to the internal datetime logic
        //eventBus.$emit('isChangedApptValidation', true)
        //this.$store.commit("setAppointment", {type: 'datetime',data: param});
        //this.$store.commit('setLeadAppointment',{date:this.selected_date,time:this.selected_time,label:show_label});
      }
      return show_label;
    },
    savedAppointment() {
      return this.$store.state.user.requested_appointment;
    },
    active() {
      const vm = this;
      if (!vm.initial_dates_loaded) {
        return false;
      }
      if (vm.user_selected_date === null) {
        return false;
      }
      let foundIndex = vm.available_dates.findIndex(function (item) {
        return item.date == vm.user_selected_date.date;
      });
      if (foundIndex !== -1) {
        return foundIndex;
      } else {
        return false;
      }
    },
    activeTime() {
      const vm = this;
      //@TODO - if a date isn't selected, we should probably always set time selection to false
      if (vm.user_selected_time !== null) {
        let foundIndex = vm.times.findIndex(function (item) {
          return item.value == vm.user_selected_time.value;
        });
        vm.times[foundIndex].active = true;
        return foundIndex;
      } else {
        return false;
      }
    },
  },
  methods: {
    next() {
      if (this.complete) {
        this.$emit("next");
      }
    },
    back() {
      this.$emit("back");
    },
    getAvailableDates() {
      const vm = this;
      vm.datesFetching = true;
      //@TODO - Need to setup the capability to pass the StoreID below *** but we are already loading this earlier in the flow, so we may be able to add logic below to check if !empty
      // Currently we're fetching dates anytime this module becomes loaded, which is probably ideal - though we may want to look at buffering the time between API queries
      if (!this.user_store) {
        var form = { type: "appointment", change: "go_to", go_to: 0 };
        this.$store.commit("FormStepBack", form);
      }
      this.$store
        .dispatch("getAvailability", this.user_store["location-id"])
        .then(function () {
          //@TODO - Update whatever local state that dates have officially loaded (may also want to load this during step 2 (service selection)
          if (vm.user_selected_date !== null) {
            if (!vm.active && vm.initial_dates_loaded) {
              //@TODO - Analyze this function trigger
              vm.$store.commit("userSelectedAppointmentDate", null);
              vm.$store.commit("userSelectedAppointmentTime", null);
            }
            if (vm.active && vm.initial_dates_loaded) {
              vm.swiperInstance.slideTo(vm.active);
            }
          }
          vm.datesFetching = false;
        });
      //@TODO - Add other dates support
      //@TODO - Add catch functionality for when Axios encounters an error
      //@TODO - Need to support querying from StoreID
    },
    addAvailableDates() {
      //@TODO - Need to associate the new time method to set the actual date and let's purge out the old code
      const vm = this;
      var lastItem = vm.available_dates.length - 1;
      var lastQueryDate = vm.available_dates[lastItem].date;
      vm.datesFetching = true;
      vm.$store.dispatch("addAvailability", lastQueryDate).then(function () {
        vm.datesFetching = false;
      });
    },
    resetActiveTime() {
      this.times.forEach((t) => {
        t.active = false;
      });
    },
    selectTime(index) {
      if (!this.times[index].disabled) {
        this.times.forEach((t, i) => {
          if (i === index) {
            t.active = !t.active;
            if (t.active) this.selected_time = t;
          } else {
            t.active = false;
          }
        });
        this.$store.commit("userSelectedAppointmentTime", this.times[index]);
      }
    },
    selectDay(item, index) {
      this.$store.commit("appointmentValidationFailed", false);
      this.$store.commit("userSelectedAppointmentDate", item);
      this.$store.commit("userSelectedAppointmentTime", null);
      this.resetActiveTime();
      this.activeDay = index;
    },
    carouselEmpty() {
      const vm = this;
      if (vm.initial_dates_loaded) {
        //this.addAvailableDates();
      }
    },
    carouselAtStart() {},
    carouselClick() {},
    indexChange() {},
    fakeIndexChange() {},
    ready() {
      this.swiperReady = true;
    },
    slideChange(e) {
      if (e.isBeginning) {
        this.navBack = false;
      } else {
        this.navBack = true;
      }
      if (
        this.swiperInstance &&
        this.swiperInstance.mounted &&
        this.initial_dates_loaded
      ) {
        if (this.swiperInstance.isEnd) {
          this.addAvailableDates();
        }
      }
    },
    detectEdge() {},
    //@TODO - Add a 'nextSlide' method that will check how many slides are left, and if > 3, return true -> API for more dates
  },
};
</script>
<style>
.sel-day-container {
  margin: 20px auto !important;
}
.swiper-slide {
  text-align: center;
}
.swiper-container {
  width: 100%;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 25px;
}
.swiper-button-prev,
.swiper-button-next {
  background: black;
  border-radius: 200%;
  width: 50px;
  height: 50px;
  color: white;
}
.swiper-button-prev.disabled {
  background: #f3f3f3;
}
.swiper-button-prev {
  left: 0px;
}
.swiper-button-next {
  right: 0px;
}
.swiper-button-next.ajax-spinner {
  visibility: hidden !important;
}
.ajax-spinner {
  position: absolute;
  right: 0;
  margin-right: 15px;
  z-index: 999;
  width: 50px;
  height: 50px;
  margin-top: 4px;
}
@media (max-width: 410px) {
  .swiper-slide-active,
  .swiper-slide-next {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
.appt-date .error-message {
  color: red;
}
</style>
